<template>
  <div class="bg lbg" style="width: 100%; height: 100%">
    <div class="loginBg">
      <div class="left">
        <img src="../../assets/img/login.png" alt="" width="228px" height="168px" />
        <div class="l_title">AI智能数字系列</div>
        <div class="desc">人工智能驱动7*24小时数字人自动化直播，实现流量精准转化和数据提升</div>
      </div>
      <div class="right">
        <div class="info">
          <div class="title">欢迎使用非相AI数字</div>
          <div style="margin-bottom: 30px">
            <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="username"></el-input>
          </div>
          <div style="margin-bottom: 20px">
            <el-input placeholder="请输入您的密码" prefix-icon="el-icon-lock" @keyup.enter.native="login" show-password v-model="password"></el-input>
          </div>
          <div>
            <el-checkbox v-model="checked">我已阅读理解并同意 <span style="color: #409eff">《用户协议》</span>和<span style="color: #409eff">《隐私协议》</span></el-checkbox>
          </div>
          <el-button type="primary" style="width: 94%; margin: 50px 3%; height: 45px" size="medium" @click="login">登 录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getUser } from "@/api/user.js";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";

export default {
  data() {
    return {
      username: "",
      password: "",
      checked: false,
    };
  },
  mounted() {
    // this.getPath();
    this.listenerClose()
  },
  methods: {
    listenerClose() {
      this.$ipc.invoke(ipcApiRoute.closeWin).then((res) => {
        console.log("关闭窗口");
      });
    },
    getPath() {
      this.$ipc.invoke(ipcApiRoute.getPath, '').then((res) => {
        console.log("res:", res);
        this.$store.commit('setSavePath',res)
      });
    },
    login() {
      // localStorage.setItem('token', '111')
      // this.$router.push({ name: 'video' })

      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.password) {
        this.$message.error("密码不能为空");
        return;
      }
      if (!this.checked) {
        this.$message.error("请勾选用户协议");
        return;
      }

      let params = {
        username: this.username,
        password: this.password,
      };
      login(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          localStorage.setItem("token", res.data.token);
          getUser({ token: res.data.token }).then((res1) => {
            if (res1.code == "200") {
              localStorage.setItem("userInfo", JSON.stringify(res1.data));
              this.$router.push({ name: "video" });
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.lbg {
  background-color: #e6ebf2;
  background-image: url("../../assets/img/bg.png");
  background-size: contain;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBg {
  width: 60vw;
  height: 35vw;
  background-color: #fff;
  -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: relative;

  .left {
    width: 23vw;
    height: inherit;
    // background-image: url(../../static/img/pic.49137f73.png);
    background: #3b49c4;
    border-radius: 20px 0 0 20px;
    padding-top: 0.8rem;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;

    .l_title {
      font-size: 29px;
      margin-top: 30px;
      font-weight: bolder;
    }

    .desc {
      width: 85%;
      text-align: center;
      margin: 17px auto;
      font-size: 14px;
      color: #d3d1d1;
    }
  }

  .right {
    width: 37vw;
    height: inherit;
    position: absolute;
    top: 0;
    left: 23vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .info {
      text-align: left;
      font-size: 15px;
      padding-top: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 10px;
        border-bottom: 2px solid #0052d9;
        margin-bottom: 30px;
        width: 60%;
      }
    }
  }
}

::v-deep {
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
